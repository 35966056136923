import { FC, SVGProps } from 'react'
import styled from 'styled-components'
import { Box, Text } from '@chakra-ui/react'

interface LocalProps {
  icon: SVGProps<SVGSVGElement>
}

type Props = BoxGlobal.WhyCP & LocalProps

const CardIcon = styled.span<{ children: any }>`
  & svg {
    margin: auto;
  }
`

const Card: FC<Props> = ({ title, description, icon }) => (
  <Box textAlign="center">
    <CardIcon>{icon}</CardIcon>
    <Text
      color="baseDark"
      fontFamily="Montserrat-SemiBold"
      fontSize="1.375rem"
      letterSpacing="0.6px"
      lineHeight="28.6px"
      whiteSpace="pre-wrap"
      marginTop="l"
      marginBottom="xs"
    >
      {title}
    </Text>
    <Text
      color="baseDark"
      fontFamily="Montserrat-Regular"
      fontSize="1rem"
      letterSpacing="0.2px"
      lineHeight="20.8px"
    >
      {description}
    </Text>
  </Box>
)

export default Card
